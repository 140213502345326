<div class="wrapper">
    <div fxLayout="row notToPrint">
        <p class="viewheader notToPrint">Wochenbericht</p>
        <div class="dateDiv notToPrint" fxFlexAlign="center" fxLayout="row">
            <p class="kwselector notToPrint">
                KW
                <i (click)="goCw(-1)" class="material-icons notToPrint">arrow_left</i>
                <span>
                    {{ dateService.date | async | moment:'ww' }}
                </span>
                <i (click)="goCw(1)" class="material-icons notToPrint">arrow_right</i>
                <i (click)="goYear(-1)" class="material-icons notToPrint">arrow_left</i>
                <span class="notToPrint">
                    {{ dateService.date | async | moment:'yyyy' }}
                </span>
                <i (click)="goYear(1)" class="material-icons notToPrint">arrow_right</i>
                <span class="btnWrapper notToPrint">
                    <button (click)="currentweek()" class="btn" mat-button>Aktuelle Woche</button>
                </span>
            </p>

        </div>
    </div>
    <div class="forPrinting">
        <p>
            Kalenderwoche
            <span>
                    {{ dateService.date | async | moment:'ww' }}
                </span>
        </p>
    </div>

    <table *ngIf="batchesToShowInReport" [dataSource]="batchesToShowInReport" class="reportsTable" fxFlexAlign="stretch" mat-table matSort>

        <!-- Date Column -->
        <ng-container matColumnDef="Datum">
            <th mat-header-cell *matHeaderCellDef class="headerCell">Datum</th>
            <td mat-cell *matCellDef="let element" class="tableCell">{{ element.date }}</td>
            <td mat-footer-cell *matFooterCellDef></td> <!-- Footer for "Datum" -->
        </ng-container>


        <!-- Zeit (ist) Column -->
        <ng-container matColumnDef="Zeit (ist)">
            <th mat-header-cell *matHeaderCellDef class="headerCell"> Zeit (ist) </th>
            <td mat-cell *matCellDef="let element" class="tableCell">
                <div *ngFor="let timeRange of getMergedProcessTimes(element)">
                    {{ timeRange }}
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef class="sumCell"> Summen </td> <!-- Footer for "Zeit (ist)" -->
        </ng-container>

        <!-- Duration Column -->
        <ng-container matColumnDef="Dauer">
            <th mat-header-cell *matHeaderCellDef class="headerCell">Dauer</th>
            <td mat-cell *matCellDef="let element" class="tableCell">
                <div fxLayout="column">
                    <table class="executionsTimeTable">
                        <!-- Dynamically add the bottom border only if it's not the last row -->
                        <tr *ngFor="let key of getGroupedExecutionKeys(element); let i = index">
                            <td class="tableCellTop">{{ convertExecutionName(key) }} :</td>
                            <td class="tableCellTop">{{ getGroupedExecutionDurations(element)[key] }}</td>
                        </tr>
                        <!-- Sum row (no bottom border) -->
                        <tr class="sumCell" *ngIf="getGroupedExecutionKeys(element)?.length > 0">
                            <td class="tableCellTop">Summe</td>
                            <td class="tableCellTop">{{ getExecutionTimeSum(element) }}</td>
                        </tr>
                    </table>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <!-- Aggregate for all processes -->
                <div fxLayout="column">
                    <table class="executionsTimeTable">
                        <tr *ngFor="let key of getGlobalExecutionKeys()">
                            <td class="tableCellTop">{{ convertExecutionName(key) }} :</td>
                            <td class="tableCellTop">{{ functionsService.durationToString(getGlobalExecutionDurations()[key]) }}</td>
                        </tr>
                        <tr class="sumCell" *ngIf="getGlobalExecutionKeys()?.length > 0">
                            <td class="tableCellTop">Summe</td>
                            <td class="tableCellTop">{{ functionsService.durationToString(getGlobalExecutionTotal()) }}</td>
                        </tr>
                    </table>
                </div>
            </td>
        </ng-container>

        <!-- Batch Name Column -->
        <ng-container matColumnDef="Charge">
            <th mat-header-cell *matHeaderCellDef class="headerCell">Charge</th>
            <td mat-cell *matCellDef="let element" class="tableCell">{{ element.name }}</td>
            <td mat-footer-cell *matFooterCellDef></td> <!-- Footer for "Charge" -->
        </ng-container>

        <!-- Input Material Column -->
        <ng-container matColumnDef="Aufgabe">
            <th mat-header-cell *matHeaderCellDef class="headerCell">Aufgabe</th>
            <td mat-cell *matCellDef="let element" class="tableCell">
                {{ element.input ? element.input.name : 'No Input' }}
            </td>
            <td mat-footer-cell *matFooterCellDef></td> <!-- Footer for "Aufgabe" -->
        </ng-container>

        <!-- Weighings Column -->
        <ng-container matColumnDef="Produkt">
            <th mat-header-cell *matHeaderCellDef class="headerCell">Produkt</th>
            <td mat-cell *matCellDef="let element" class="tableCell">
                <ng-container *ngIf="element.weighings?.length > 0; else noWeights">
                    <div *ngIf="element.weighings?.length">
                        <div *ngFor="let weighing of getAllWeighings(element)">
                            {{ weighing.materialName }}: {{ weighing.netWeight | number:'1.3-3' }} t
                        </div>
                    </div>
                </ng-container>
                <ng-template #noWeights>
                    <span>-</span>
                </ng-template>
            </td>
            <!-- Footer for Weighings Aggregate -->
            <td mat-footer-cell *matFooterCellDef>
                <ng-container *ngFor="let product of getGroupedProducts()">
                    <div>
                        {{ product.name }}: {{ product.total | number:'1.3-3' }} t
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <!-- Header, Row, Footer Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;">
        </tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="notToPrint"></tr>
    </table>

    <div *ngIf="batchesToShowInReport" class="forPrinting forPrintingSummary">
        <div class="aggregatesWrapper">
            <!-- Sum of Execution Times -->
            <div>
                <p class="aggregateHeader">Gesamtsumme von Ausführungszeiten</p>
                <table class="executionsTimeTable">
                    <tr *ngFor="let key of getGlobalExecutionKeys()">
                        <td>{{ convertExecutionName(key) }}:</td>
                        <td>{{ functionsService.durationToString(getGlobalExecutionDurations()[key]) }}</td>
                    </tr>
                    <tr class="sumCell" *ngIf="getGlobalExecutionKeys()?.length > 0">
                        <td>Summe</td>
                        <td>{{ functionsService.durationToString(getGlobalExecutionTotal()) }}</td>
                    </tr>
                </table>
            </div>

            <!-- Sum of Weighings -->
            <div>
                <p class="aggregateHeader">Gesamtsumme von Wiegungen</p>
                <ng-container *ngFor="let product of getGroupedProducts()">
                    <div>
                        {{ product.name }}: {{ product.total | number:'1.3-3' }} t
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

</div>