import {Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration} from '@azure/msal-angular';
import {
    AuthenticationResult,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest
} from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {GetProfileService} from './Services/get-profile.service';
import {DataShareService} from './Services/data-share.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {QuestionBoxComponent} from './Dialogs/question-box/question-box.component';
import {VERSION} from '../environments/version';
import {SwUpdate, VersionEvent, VersionReadyEvent} from '@angular/service-worker';
import {MessageboxComponent} from './Dialogs/messageb/messagebox.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {APIService} from './Services/api.service';
import {AccessTypes} from './Enums/access-types';
import {AccessAppAreas} from './Enums/access-app-areas';
import {UserAccessService} from './Services/user-access.service';
import {DOCUMENT, formatDate} from '@angular/common';
import {environment} from '../environments/environment';
import {NavService} from "./Services/navigation-service/nav.service";
export interface NavItem {
    displayName: string;
    disabled?: boolean;
    route?: string;
    children?: NavItem[];
    accessTypes?: AccessTypes;
    accessAreas?: AccessAppAreas;
}


// SERVICE WORKER: https://angular.io/guide/service-worker-getting-started

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    protected readonly environment = environment;
    @ViewChild('refreshForm', { static: false }) refreshForm;
    @ViewChild('sideNav') sideNav: ElementRef;
    title = 'SchauflerARP';
    isIframe = false;
    loginDisplay = false;
    private readonly destroying$ = new Subject<void>();
    public appURL: string;
    public portalURL: string;
    public profile: any;
    public config: any;
    public accessTyps = AccessTypes;
    public accesAreas = AccessAppAreas;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private dataService: DataShareService,
        private ProfileService: GetProfileService,
        public dialog: MatDialog,
        public router: Router,
        public updates: SwUpdate,
        private apiService: APIService,
        public userAccess: UserAccessService,
        private navService: NavService
    ) {

    }

    ngAfterViewInit() {
        this.navService.sideNav = this.sideNav;
    }

    navItems: NavItem[] = [
        {
            displayName: 'Home',
            route: '/home',
        },
        {
            displayName: 'Konfiguration',
            children: [
                {
                    displayName: 'Typen',
                    route: '/resourcetypes',
                    accessAreas: AccessAppAreas.resource_type,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Inventar',
                    route: '/resource-view',
                    accessAreas: AccessAppAreas.resource,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Prozesse',
                    route: '/process',
                    accessAreas: AccessAppAreas.process,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Tara',
                    disabled: true,
                    accessAreas: AccessAppAreas.mover,
                    accessTypes: AccessTypes.view,
                    children: [
                        {
                            displayName: 'Fahrzeuge',
                            route: '/mover-view',
                            accessAreas: AccessAppAreas.mover,
                            accessTypes: AccessTypes.view

                        },
                        {
                            displayName: 'Container',
                            route: '/container-view',
                            accessAreas: AccessAppAreas.container,
                            accessTypes: AccessTypes.view
                        },
                    ]
                },
                {
                    displayName: 'Einstellungen',
                    route: '/settings',
                    accessAreas: AccessAppAreas.settings,
                    accessTypes: AccessTypes.view
                }
            ]
        },
        {
            displayName: 'Aufbereitung',
            children: [
                {
                    displayName: 'Chargen',
                    route: '/batches',
                    accessAreas: AccessAppAreas.batch,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Kalender',
                    route: '/calendar',
                    accessAreas: AccessAppAreas.plan,
                    accessTypes: AccessTypes.view
                }
            ]
        },
        {
            displayName: 'Auswertung',
            children: [
                {
                    displayName: 'Abrechnung',
                    route: '/calculation',
                    accessAreas: AccessAppAreas.calculation,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Wiegungen',
                    route: '/weighing-filter',
                    accessAreas: AccessAppAreas.weighing_filter,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Wochenbericht',
                    route: '/week-report',
                    accessAreas: AccessAppAreas.week_report,
                    accessTypes: AccessTypes.view
                },
                {
                    displayName: 'Auslastung',
                    route: '/resource-usage',
                    accessAreas: AccessAppAreas.resource_usage,
                    accessTypes: AccessTypes.view
                }
            ]
        },
    ];

    private setupUpdateCheck() {
        if (this.updates.isEnabled) {
            // Detect version changes using versionUpdates
            this.updates.versionUpdates.subscribe((event: VersionEvent) => {
                if (event.type === 'VERSION_READY') {
                    // A new version is available
                    const newVersionEvent = event as VersionReadyEvent;
                    console.log('Neue Version verfügbar:', newVersionEvent);

                    // Ask user if they want to update
                    const content = 'Eine neue Version ist verfügbar. Möchten Sie jetzt aktualisieren?';
                    const dialogRef = this.dialog.open(MessageboxComponent, {
                        panelClass: 'panelclass',
                        data: content
                    });

                    dialogRef.afterClosed().subscribe((confirmed) => {
                        if (confirmed) {
                            this.updates.activateUpdate().then(() => {
                                window.location.reload();
                            });
                        }
                    });
                }
            });
        }
    }

    ngOnInit(): void {
        this.setupUpdateCheck();

        this.config = VERSION;
        this.appURL = environment.redirectUri;
        this.portalURL = environment.portalUrl;



        this.ProfileService.profile$.subscribe(profile => {
            this.profile = profile;
        });

        this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {


                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
                this.ProfileService.getProfile();

                const accessTokenRequest = {
                    scopes: ['user.read'],
                    account: this.authService.instance.getActiveAccount()
                };

                this.authService.acquireTokenSilent(accessTokenRequest).subscribe((data: any) => {
                    console.log(data);
                    console.log('get token success');
                    this.apiService.SetTokenAquiringFinished();
                }, () => {
                    console.log('no token');
                });

                if (this.router.url === '/') {
                    this.router.navigate(['/home']);
                }
            });
    }



    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        console.log('popup login');
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup()
                    .subscribe((response: AuthenticationResult) => {
                        console.log('without popup login');
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                console.log('redirect called with request');
                this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
            } else {
                console.log('redirect called without request');
                this.authService.loginRedirect();
            }
        }
    }

    redirectToPortal() {
        if (environment.portalUrl !== '') {
            this.document.location.href = environment.portalUrl;
        }
        else {
            this.authService.logout();
            this.router.navigate(['']);
        }
    }


    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
    }


    public getCurrentVersion(): string {
        return VERSION.semver.version;
    }

    public getCurrentFullVersion(): string {
        return VERSION.semverString;
    }

    public RouteTo(route: string) {
        if (this.dataService.activeEdit === true) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'dialogStyles';
            dialogConfig.data = 'Ihre Änderungen werden verworfen wenn Sie die aktuelle Ansicht verlassen. Möchten Sie Ihre Änderungen wirklich verwerfen?';
            const dialogRef = this.dialog.open(QuestionBoxComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(data => {
                if (data === true) {
                    this.dataService.activeEdit = false;
                    this.router.navigate([route]);
                }
            });
        } else {
            this.router.navigate([route]);
        }
    }

}
